import { template as template_8207e96928e14f8ca2e072aedc6bfeeb } from "@ember/template-compiler";
const FKLabel = template_8207e96928e14f8ca2e072aedc6bfeeb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
