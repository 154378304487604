import { template as template_bbb4484f662140a6873703c18db2d9f1 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_bbb4484f662140a6873703c18db2d9f1(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
